import React, {useEffect} from "react";
import {fetchNftOwners} from "@/api/reservoir/useNFTOwners";
import {AddressZero} from "@ethersproject/constants";
import {OwnerTokens, useSelectNFTs} from "@/components/ERC721/OwnerTokens";
import {IoMdArrowRoundBack, IoMdArrowRoundForward, IoMdSwap} from "react-icons/io";
import {ButtonType} from "@/components/Button";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {formatValue, toBN} from "@/util/converters";
import {TokenApprovalButton} from "@/components/ERC721/TokenApprovalButton";
import {BT404MirrorWrapper} from "@/contract/BT404MirrorWrapper";
import {useReadErc20BalanceOf, useReadErc721BalanceOf} from "@/util/generated";
import {Address, parseUnits} from "viem";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {AddressDisplay} from "@/components/Account/AddressDisplay";
import {useHasMounted} from "@/hooks/useHasMounted";
import {MAX_VALUE, TokenAllowanceButton} from "@/components/ERC20/TokenAllowanceButton";
import {BigNumber} from "ethers";
import {WasabiVaultAbi} from "@/contract/WasabiVaultAbi";
import {useRouter} from "next/router";
import {useCurrentUser} from "@/hooks/useCurrentUser";

const Blastopians = {
  ogNFTAddress: "0xda0005f01654bd92ebec85cf82ed1a4e2b3ce70f",
  wrapper: "0xc8d8d820f88df3bd48c4f8e95bca3b994b73c699", // erc20
  mirrorWrapper: "0xb0a19fa556ab92e51ea7969ef5fefef5a357870c", // erc721
  vaultAddress: "0xc8061516994aa5d884fdf6385c6b64e7b9e93014",
  vaultName: "wmBLASTOPIANS",
}

export const Fractionalize = () => {
  const hasMounted = useHasMounted();
  const router = useRouter();
  const {address} = useCurrentUser();
  const ogSelected = useSelectNFTs();
  const wrappedSelected = useSelectNFTs();

  const ogBalance = useReadErc721BalanceOf({
    address: Blastopians.ogNFTAddress as Address,
    args: [(address || AddressZero) as Address],
    query: { enabled: !!address }
  });
  const wrappedBalance = useReadErc721BalanceOf({
    address: Blastopians.mirrorWrapper as Address,
    args: [(address || AddressZero) as Address],
    query: { enabled: !!address }
  });
  const fractionalTokenBalance = useReadErc20BalanceOf({
    address: Blastopians.wrapper as Address,
    args: [(address || AddressZero) as Address],
    query: { enabled: !!address }
  })

  const getSelectedCount = () => (ogSelected.selectedTokenIds?.length || 0) + (wrappedSelected.selectedTokenIds?.length || 0);
  const anySelected = () => getSelectedCount() > 0;
  const isWrapNFT = () => (ogSelected.selectedTokenIds?.length || 0) > 0;

  useEffect(() => {
    if (ogSelected?.selectedTokenIds && ogSelected.selectedTokenIds.length > 0) {
      wrappedSelected.onChange([]);
    }
  }, [ogSelected.selectedTokenIds]);

  useEffect(() => {
    if (wrappedSelected?.selectedTokenIds && wrappedSelected.selectedTokenIds.length > 0) {
      ogSelected.onChange([]);
    }
  }, [wrappedSelected.selectedTokenIds]);

  const fractionalizeChain: ChainItem[] = [];
  const stakeChain: ChainItem[] = [];
  if (isWrapNFT()) {
    const item: ChainItem = {
      name: "Allow NFT Transfers",
      description: "Allow the transfer of your NFTs to be wrapped",
      stepRenderer: (onSuccess, onFailure) =>
        <TokenApprovalButton
          contractAddress={Blastopians.ogNFTAddress}
          to={Blastopians.mirrorWrapper}
          singleToken={false}
          autoStart={true}
          className="capitalize text-xs w-full"
          approvalChanged={approved => {
            if (approved) {
              onSuccess();
            }
          }}
        />
    };
    fractionalizeChain.push(item);
    stakeChain.push(item);
  }

  if (isWrapNFT()) {
    const item: ChainItem = {
      name: "Fractionalize NFTs",
      description: <ul className="list-disc px-4 text-sm">
        <li>Receiving the same NFT that was originally staked is not guaranteed.</li>
        <li>If your Blastopian is already staked on the Blastopian site,<br/>you will not lose your points streak when staking on Wasabi.</li>
      </ul>,
      button: {
        id: "wrapNFT",
        loadingText: "Fractionalizing...",
        addressOrName: Blastopians.mirrorWrapper,
        functionName: "wrapBatch",
        contractInterface: BT404MirrorWrapper,
        args: [ogSelected.selectedTokenIds],
        enabled: anySelected(),
      }
    };
    fractionalizeChain.push(item);
    stakeChain.push(item);
  } else {
    fractionalizeChain.push({
      name: "Redeem OG NFTs",
      description: "Redeem OG NFTs",
      button: {
        id: "unwrapNFT",
        loadingText: "Redeeming...",
        addressOrName: Blastopians.mirrorWrapper,
        functionName: "unwrapBatch",
        contractInterface: BT404MirrorWrapper,
        args: [wrappedSelected.selectedTokenIds || []],
        enabled: anySelected(),
      }
    });
  }

  stakeChain.push({
    name: "Allow Token Transfers",
    description: "Allow the transfer of your token to the vault",
    stepRenderer: (onSuccess, onFailure) =>
      <TokenAllowanceButton
        autoStart={true}
        spender={Blastopians.vaultAddress}
        requestMaxValue={true}
        amount={BigNumber.from(parseUnits((getSelectedCount() * 1000).toString(), 18))}
        contractAddress={Blastopians.wrapper}
        approvalChanged={approved => {
          if (approved) {
            onSuccess();
          }
        }}
        className="capitalize text-xs w-full"/>
  });

  stakeChain.push({
    name: "Stake Tokens",
    description: "Stake Tokens",
    button: {
      id: "deposit",
      loadingText: "Depositing...",
      addressOrName: Blastopians.vaultAddress,
      functionName: "deposit",
      contractInterface: WasabiVaultAbi,
      args: [parseUnits((getSelectedCount() * 1000).toString(), 18), address],
      enabled: !!address,
    }
  })

  return (
    <div className="w-full standard-stack !gap-4 items-center justify-center p-4">
      <h1 className="text-2xl">Fractionalize & Stake</h1>
      <div className="text-lg">
        Earn Blast Gold, Wasabi Points, Blastopian Points and yield
      </div>
      {
        hasMounted &&
        <>
          <div className="w-full responsive-flex gap-4 items-center justify-center max-w-[1000px]">
            <div className="standard-stack">
              <div className="standard-frame standard-stack p-4 min-h-[110px]">
                <div className="flex flex-row gap-2 items-center justify-between">
                  <span>Blastopians - ERC721</span>
                  <AddressDisplay
                    displayIcon={true}
                    address={Blastopians.ogNFTAddress}
                    label="Block Explorer"
                    className="text-neutral-content text-xs"
                  />
                </div>
                <SummaryItem<BigInt>
                  label="Num Owned"
                  className="text-sm"
                  isLoading={ogBalance.isLoading}
                  isError={ogBalance.isError}
                  data={ogBalance.data}
                  disabled={!address}>
                  { v =>
                    <span className="flex flex-row items-center text-white text-md">{v.toString() || 0}</span>
                  }
                </SummaryItem>
              </div>
              <OwnerTokens
                {...ogSelected}
                id="og_owned"
                noTokensText="No ERC721 tokens found."
                className="w-[300px] h-[300px]"
                owner={address || AddressZero}
                contractAddress={Blastopians.ogNFTAddress}
                fetcher={fetchNftOwners}
                autoSelectAll={true}
              />
            </div>
            {
              (ogSelected.selectedTokenIds?.length || 0) > 0
                ? <IoMdArrowRoundForward size={30} />
                : (wrappedSelected.selectedTokenIds?.length || 0) > 0
                  ? <IoMdArrowRoundBack size={30} />
                  : <IoMdSwap size={30} />
            }
            <div className="standard-stack">
              <div className="standard-frame standard-stack p-4 min-h-[110px]">
                <div className="flex flex-row gap-2 items-center justify-between">
                  <span>mBlastopians - BT404</span>
                  <AddressDisplay
                    displayIcon={true}
                    address={Blastopians.mirrorWrapper}
                    label="Block Explorer"
                    className="text-neutral-content text-xs"
                  />
                </div>
                <SummaryItem<BigInt>
                  label="Token Balance"
                  className="text-sm"
                  isLoading={fractionalTokenBalance.isLoading}
                  isError={fractionalTokenBalance.isError}
                  data={fractionalTokenBalance.data}
                  disabled={!address}>
                  { v =>
                    <span className="flex flex-row items-center text-white text-md">{formatValue(v.valueOf()).toLocaleString()}</span>
                  }
                </SummaryItem>
                <SummaryItem<BigInt>
                  label="Num NFTs Owned"
                  className="text-sm"
                  isLoading={wrappedBalance.isLoading}
                  isError={wrappedBalance.isError}
                  data={wrappedBalance.data}
                  disabled={!address}>
                  { v =>
                    <span className="flex flex-row items-center text-white text-md">{v.toString()}</span>
                  }
                </SummaryItem>
              </div>
              <OwnerTokens
                {...wrappedSelected}
                id="wrapped"
                noTokensText="No BT404 tokens found."
                className="w-[300px] h-[300px]"
                owner={address || AddressZero}
                contractAddress={Blastopians.mirrorWrapper}
                fetcher={fetchNftOwners} />
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            {
              anySelected() ?
                <>
                  <TransactionChain
                    buttonType={ButtonType.SECONDARY}
                    id={anySelected() ? "empty" : isWrapNFT() ? "fractionalize" : "redeem"}
                    title={anySelected() ? (isWrapNFT() ? "Fractionalize" : "Redeem NFTs") : "Select NFTs"}
                    transactionChain={fractionalizeChain}
                    enabled={anySelected()}
                    onSuccess={() => {
                      setTimeout(() => window.location.reload(), 2000);
                    }}
                  />
                  <TransactionChain
                    buttonType={ButtonType.PRIMARY}
                    id={anySelected() ? "empty2" : isWrapNFT() ? "Fractionalize & Stake" : `Stake ${getSelectedCount()} NFT${getSelectedCount() > 1 ? 's' : ''}`}
                    title={anySelected() ? (isWrapNFT() ? "Fractionalize & Stake" : `Stake ${getSelectedCount()} NFT${getSelectedCount() > 1 ? 's' : ''}`) : "Select NFTs"}
                    transactionChain={stakeChain}
                    enabled={anySelected()}
                    onSuccess={() => {
                      setTimeout(() => {
                        router.push(`/vaults?vault=${Blastopians.vaultName}`);
                      }, 2000);
                    }}
                  />
                </> :
                <div className="wasabi-button wasabi-button-neutral">Select NFTs To Start</div>
            }
          </div>
        </>
      }
    </div>
  )
};
