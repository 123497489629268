import {useAccount, useContractRead} from "wagmi";
import {useEffect, useState} from "react";
import {BigNumber} from "ethers";
import {EthDisplay} from "@/components/EthDisplay";
import {formatEther, toBN} from "@/util/converters";
import {googlePushDataLayer} from "@/util/analytics";
import {TransactionButtonProps, TransactionButton} from "@/components/Transactions/TransactionButton";
import {useReadErc20Allowance} from "@/util/generated";
import {Address} from "viem";
import { erc20Abi } from 'viem';

export const MAX_VALUE = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");

export const useTokenAllowance = () => {
  const [approved, approvalChanged] = useState<boolean>(false);
  return {approved, approvalChanged};
}

/**
 * We have to get approval from the user by calling ERC20#approve for all, before we are
 * allowed to transfer their token. This is a component that gathers all the logic within.
 */
export const TokenAllowanceButton = ({
  contractAddress,
  spender,
  approvalChanged,
  amount,
  numDigits = 2,
  className = "",
  requestMaxValue = false,
  autoStart
}: {
  contractAddress: string;
  spender: string;
  approvalChanged: (isApproved: boolean) => void;
  amount: BigNumber;
  className?: string;
  numDigits?: number;
  requestMaxValue?: boolean;
  autoStart?: boolean
}) => {
  const { address: owner } = useAccount();
  const [approved, setApproved] = useState(false);

  const { refetch, isLoading: isReading, isSuccess, data } = useReadErc20Allowance({
    address: contractAddress as Address,
    args: [owner as Address, spender as Address],
    query: { enabled: owner !== undefined }
  })

  useEffect(() => {
    if (isSuccess && data) {
      const allowance = toBN(data);
      let approved = allowance.gte(amount);
      setApproved(approved);
      approvalChanged(approved);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [amount]);

  const transactionButtonProps: TransactionButtonProps = {
    id: "allow_erc20_transfer",
    alertOnError: true,
    addressOrName: contractAddress,
    contractInterface: erc20Abi,
    functionName: "approve",
    args: [spender, requestMaxValue ? MAX_VALUE : amount],
    enabled: owner !== undefined,
    loadingText: "Getting Approval...",
    onTransactionSuccess: () => {
      refetch();
      googlePushDataLayer('tokenAllowance', {
        'tokenAllowance': contractAddress,
        'tokenAllowanceAmount': requestMaxValue ? 'MAX_VALUE' : formatEther(amount).toString(),
        'spender': spender,
      });
    }
  }

  return (
    <TransactionButton {...transactionButtonProps}
                       autoStart={autoStart && isSuccess && data !== undefined && BigNumber.from(data).lt(amount.toBigInt())}
                       disabled={approved && isReading}
                       loading={isReading}
                       className={className}>
      {!isReading && approved && "Token Transfer Approved"}
      {!isReading && !approved &&
        (
          requestMaxValue
            ? <>Approve transfer of your tokens</>
            : <EthDisplay label="Approve transfer of"
                          value={amount}
                          tokenAddress={contractAddress}
                          size={4}
                          removeTrailingZeroes={true}
                          numDigits={numDigits || 2} />
        )
      }
      {isReading && "Looking Up Approval"}
    </TransactionButton>
  );
}
