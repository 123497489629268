import {SplitLabelValue} from "@/components/SplitLabelValue";
import React from "react";

export type Props<T> = {
  className?: string | undefined
  children: (v: T) => any;
  label: string | React.ReactNode;
  tooltip?: string | React.ReactNode | undefined;
  isLoading: boolean;
  isError: boolean;
  data: T | undefined;
  disabled?: boolean | undefined;
  isRow?: boolean | undefined;
}

export const SummaryItem = <T extends any>({
  children, label, tooltip, isError, isLoading, data, disabled, className, isRow
}: Props<T>) => {
  return (
    <SplitLabelValue label={label} tooltip={tooltip} className={className || ''} isRow={isRow}>
      {
        isLoading ?
          <div className="min-w-[100px] max-w-[200px] min-h-[16px] rounded-md bg-slate-600 animate-pulse"/> :
          (disabled || isError || data === undefined) ?
            "--" :
            <span className="text-white">{ children(data) }</span>
      }
    </SplitLabelValue>
  )
}